import { ReactComponent as ArrowRight } from "app/assets/svg/arrow-right.svg"
import { useEffect } from "react"
import { SubmitHandler } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { Navigate, useNavigate } from "react-router-dom"
import { useTranslate } from "hooks/translate"
import {
  getFormData, getLeadResponseStatus
} from "state/preownedForm/selectors"
import {
  trackLeadThunk
} from "state/preownedForm/slice"
import { PreOwnedFormStateData } from "state/preownedForm/types"
import { checkVATNumber } from "app/api"
import Form from "app/components/Form"
import Heading from "app/components/Heading"
import Description from "app/components/Description"
import Body from "app/components/Form/Body"
import InputField from "app/components/Form/Field/Input"
import SelectField from "app/components/Form/Field/Select"
import { StyledHeader } from "app/components/Form/Header/style"
import LanguageSwitch from "app/components/LanguageSwitch"
import {
  StyledFooter,
  StyledButtonLoadingIndicator,
  StyledButtonLoadingIndicatorWrapper
} from "app/components/Form/Footer/style"
import FormButton from "app/components/Form/Button"
import { Locales } from "i18n"
import { parseLinks } from "i18n/utils/parseLinks"
import { getAppLocale } from "state/app/selectors"
import { StyledLabel } from "app/components/Form/Field/Checkbox/style"

enum PreferredLanguages {
  NL = "nl",
  FR = "fr",
  EN = "en"
}

const legalLinks: {
  [key in Locales]: {
    [key: string]: string
  }
} = {
  [Locales.NL]: {
    link: "https://www.polestar.com/nl-be/legal/privacy/privacy-policy/#marketing-via-e-mail-and-websites"
  },
  [Locales.FR]: {
    link: "https://www.polestar.com/fr-be/legal/privacy/privacy-policy/#marketing-via-e-mail-and-websites"
  }
}

const NewCarFormPage: React.FC = () => {
  const formData = useSelector(getFormData)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslate()
  const { isPending, isRejected } = useSelector(getLeadResponseStatus)
  const locale = useSelector(getAppLocale)

  if (isRejected) {
    return <Navigate to="/error" replace />
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [])

  const preferredLanguageOptions = Object.values(PreferredLanguages).map(
    locale => ({
      label: t(`system.locale.${locale}`),
      value: locale
    })
  )

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
  }, [])

  const handleFormSubmit: SubmitHandler<PreOwnedFormStateData> = async payload => {
    dispatch(trackLeadThunk({
      ...payload,
      newCar: false,
      privacyPolicy: true,
    } as any)) // TODO: If you have some extra time feel free to fix the typing and remove the any
    navigate(`/preowned/success`)
  }

  return (
    <>
      <StyledHeader>
        <LanguageSwitch />
      </StyledHeader>
      <Body>
        <Heading>{t("preownedform.heading")}</Heading>
        <Description>{t("preownedform.body")}</Description>
      </Body>
      <Form
        defaultValues={formData}
        onSubmit={handleFormSubmit}
        isPending={isPending}
      >
        <InputField
          name="firstName"
          label="system.first_name"
          rules={{ required: true }}
        />
        <InputField
          name="lastName"
          label="system.last_name"
          rules={{ required: true }}
        />
        <InputField
          name="email"
          label="system.email"
          inputMode="email"
          rules={{
            required: true,
            pattern: {
              value: /^\S+@\S+\.\S+$/i,
              message: t("error.email")
            }
          }}
        />
        <InputField
          name="phone"
          label="system.phone"
          description={t("preownedform.field.phone.description")}
          type="tel"
          rules={{
            required: true,
            pattern: {
              value: /^\+32[^0][0-9]{6,}$/,
              message: t("error.phone")
            }
          }}
        />
        <SelectField
          name="language"
          label="preownedform.field.preferred_language.label"
          options={preferredLanguageOptions}
          rules={{ required: true }}
        />
        <InputField
          name="enterpriseNumber"
          label="system.enterprise_number"
          rules={{
            validate: {
              enterprise_number: async enterpriseNumber =>
                enterpriseNumber ? await checkVATNumber(enterpriseNumber) : true
            }
          }}
          tooltip={t("tooltip.enterprise_number")}
          description={t("form.field.enterpriseNumber.description")}
        />
        <InputField
          name="enterpriseName"
          label="system.enterprise_name"
          rules={{ maxLength: 35 }}
        />
        <InputField
          name="carModel"
          label="form.field.car_model.label"
        />

        <StyledLabel>
          {t(
            "preownedform.field.privacy_policy.label",
            parseLinks({
              link: {
                target: "_blank",
                href: legalLinks[locale].link
              },
            })
          )}
        </StyledLabel>

        <StyledFooter>
          <FormButton
            key={"button-next-preowned"}
            icon={ArrowRight}
            iconPlacement="postfix"
            action="next"
            disabled={isPending}
          >
            {isPending && (
              <StyledButtonLoadingIndicatorWrapper>
                <StyledButtonLoadingIndicator />
              </StyledButtonLoadingIndicatorWrapper>
            )}
            {t("preownedform.system.complete")}
          </FormButton>
        </StyledFooter>
      </Form>
    </>
  )
}

export default NewCarFormPage
